<template>
  <div :class="['mb-3', divClass, { 'invalid-file': hasError }]">
    <s-label :label="label" :required="required" />
    <div class="custom-file-input-container" @click="openFileDialog">
      <template v-if="selectedFile && selectedFile.name">
        <span class="selected-filename">{{ selectedFile.name }}</span>
      </template>
      <template v-else>
        <i class="bi bi-image"></i>
        <span v-if="hasError">O arquivo selecionado não é um vídeo.</span>
        <span v-else>Insira seu vídeo aqui</span>
      </template>
      <input
        ref="fileInput"
        type="file"
        accept="video/*"
        @change="handleFileChange"
        class="custom-file-input"
        :class="{ 'is-invalid': hasError }"
      />
      <s-button
        class="rounded-pill custom-s-button"
        label="Pesquisar"
        color="white"
        colorFont="gray"
        @click.stop.prevent="openFileDialog"
      />
    </div>
    <div class="invalid-feedback" v-if="hasError">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheInputVideo',
  inheritAttrs: false,

  props: {
    divClass: String,
    label: String,
    required: Boolean,
    selectedFile: File,
  },

  data: () => ({
    error: null,
    hasError: false
  }),

  methods: {
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      this.$emit('fileSelected', selectedFile);
    },

    updateValue() {
      if (this.$refs.fileInput.files.length === 0) {
        this.handleError('Nenhum arquivo foi selecionado.')
        return
      }

      if (this.isVideoFile(this.selectedFile)) {
        this.hasError = false
        this.$refs.fileInput.value = null;

        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(new File([this.selectedFile], this.selectedFile.name));
        this.$refs.fileInput.files = dataTransfer.files;
      } else {
        this.handleError('O arquivo selecionado não é um vídeo.')
        this.$refs.fileInput.value = null;
      }
    },

    isVideoFile (file) {
      return file && file.type.startsWith('video/')
    },

    handleError (errorMessage) {
      this.error = errorMessage || 'Ocorreu um erro.'
      this.hasError = true
    },

    openFileDialog () {
      this.$refs.fileInput.click()
    }
  },

  watch: {
    selectedFile() {
      this.updateValue()
    }
  }
});
</script>

<style>
.custom-file-input-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
  color: #555;
}

.custom-file-input-container i {
  font-size: 20px;
  margin-right: 10px;
}

.custom-file-input {
  display: none;
}

.custom-file-input-container:hover {
  border-color: #888;
}

.custom-s-button {
  background-color: white;
  color: gray;
  border: 1px solid gray;
  margin-left: auto;
}

.selected-filename {
  background-color: #eee; /* Light gray background */
  padding: 5px 10px; /* Some padding */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Make text bold */
}

.invalid-file .custom-file-input-container {
  border-color: red; /* Defina a cor vermelha para a borda quando inválido */
}

/* Se desejar tornar o texto vermelho também */
.invalid-file .custom-file-input-container span {
  color: red;
}
</style>
