<template>
  <div class="text-center loader-overlay">
    <h5>{{ text }}</h5>
    <div class="spinner-border" role="status"></div>
  </div>
</template>

<script>
  export default ({
    name: 'TheLoader',

    props: {
      text: {
        type: String,
        default: 'Carregando dados, aguarde...'
      },
    },
  })
</script>

<style>
.spinner-border {
  width: 3rem;
  height: 3rem;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #562875;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 90;
  }
</style>