<template>
   <div :class="`mb-3 ${divClass}`">
    <s-label :label="label" :required="required" />
    <div class="position-relative">
      <select
        ref="theSelect"
        v-model="inputValue"
        @change="updateValue"
        :disabled="isDisabled"
        :class="{ 'is-invalid': hasError }"
        @blur="updateValue"
      >
        <option v-for="(item, index) in items" :key="index" :value="item.value">{{ item.label }}</option>
      </select>
      <div class="icon-container" @click="emitClick">
        <i :class="icon"></i>
      </div>
      <button class="clear" @click="clear" v-if="inputValue != null && !isDisabled && clearable">
        <i class="bi bi-x-lg"></i>
      </button>
      <div class="invalid-feedback" v-if="hasError">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { validateRequired } from '@/rule/validate'

export default defineComponent({
    props: {
      ref: String,
      divClass: String,
      label: String,
      required: Boolean,
      modelValue: String,
      isDisabled: Boolean,
      items: Array,
      clearable: Boolean,
      icon: String,
    },

    inheritAttrs: false,

    data: () => ({
      inputValue: '',
      error: null,
      hasError: false,
    }),

    methods: {
      update() { this.$emit('update:modelValue', this.inputValue) },

      updateValue() {
        validateRequired(
          this.inputValue,
          this.required,
          this,
          `${this.label} é obrigatório.`,
        )

        this.update()
        },

        clear() {
          this.inputValue = null
          this.updateValue()
        },

        emitClick() {
          this.$emit('clicked')
        }
    },

    emmits: [ "update:modelValue" ],

    created() { this.inputValue = this.modelValue },

    watch: {
      inputValue() { this.update() },

      modelValue() {
        if (this.modelValue != this.inputValue) {
          this.inputValue = this.modelValue
        }
      }
    }
})
</script>

<style>
  .clear {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
  }

  select {
    padding: 0;
    margin: 0;
    min-width: 100%;
    background-color: #f3eeff;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 6px;
    border-radius: 8px;
    outline: none;
    border: none;
    color: #000f08;
    font-size: 10px;
  }

  select:focus {
    outline: none;
    border: 1px solid #602A84;
  }

  select:hover {
    outline: none;
    border: 1px solid #602A84;
  }

  .icon-container {
    position: absolute;
    top: 38%;
    right: 0px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
  }
</style>