import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Vue from 'vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Dashboard.vue'),
  },
  {
    path: '/recoveryPassword/:token',
    name: 'recoveryPassword',
    component: () => import('@/components/login/RecoveryPassword.vue'),
  },
  {
    path: '/candidate/:uid',
    name: 'candidateUid',
    component: () => import('@/views/Candidate.vue'),
    props: true,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
  },
  /* ########## USUARIO ########## */
  {
    path: '/administracao/usuario',
    name: 'user',
    component: () => import('@/views/administracao/usuario/Usuario.vue'),
  },
  {
    path: '/administracao/usuario/cadastro/novo',
    name: 'userNew',
    component: () => import('@/views/administracao/usuario/UsuarioNovo.vue'),
  },
  {
    path: '/administracao/usuario/:id/editar',
    name: 'userUpdate',
    component: () => import('@/views/administracao/usuario/UsuarioNovo.vue'),
  },
  /* ########## GRUPO USUÁRIO ########## */
  {
    path: '/administracao/usuario/:idUser/grupoUsuario',
    name: 'userGroupChildren',
    component: () => import('@/views/administracao/usuario/grupoUsuario/GrupoUsuarioPai.vue'),
  },
  {
    path: '/administracao/usuario/:idUser/grupoUsuario/cadastro/novo',
    name: 'userGroupNew',
    component: () => import('@/views/administracao/usuario/grupoUsuario/GrupoUsuarioNovo.vue'),
  },
  {
    path: '/administracao/usuario/:idUser/grupoUsuario/:id/editar',
    name: 'userGroupUpdate',
    component: () => import('@/views/administracao/usuario/grupoUsuario/GrupoUsuarioNovo.vue'),
  },
  /* ########## GRUPO ########## */
  {
    path: '/administracao/grupo',
    name: 'group',
    component: () => import('@/views/administracao/grupo/Grupo.vue'),
  },
  {
    path: '/administracao/grupo/cadastro/novo',
    name: 'groupNew',
    component: () => import('@/views/administracao/grupo/GrupoNovo.vue'),
  },
  {
    path: '/administracao/grupo/:id/editar',
    name: 'groupUpdate',
    component: () => import('@/views/administracao/grupo/GrupoNovo.vue'),
  },
  /* ########## GRUPO ACL ########## */
  {
    path: '/administracao/grupo/:id_group/acl',
    name: 'aclChildren',
    component: () => import('@/views/administracao/grupo/acl/AclPai.vue'),
  },
  {
    path: '/administracao/grupo/:id_group/acl/novo',
    name: 'aclNew',
    component: () => import('@/views/administracao/grupo/acl/AclNovo.vue'),
  },
  {
    path: '/administracao/grupo/:id_group/acl/:id/editar',
    name: 'aclUpdate',
    component: () => import('@/views/administracao/grupo/acl/AclNovo.vue'),
  },
  /* ########## PROGRAMA ########## */
  {
    path: '/administracao/programa',
    name: 'screen',
    component: () => import('@/views/administracao/programa/Programa.vue'),
  },
  {
    path: '/administracao/programa/cadastro/novo',
    name: 'screenNew',
    component: () => import('@/views/administracao/programa/ProgramaNovo.vue'),
  },
  {
    path: '/administracao/programa/:id/editar',
    name: 'screenUpdate',
    component: () => import('@/views/administracao/programa/ProgramaNovo.vue'),
  },
  /* ########## MONITOR DE PROCESSOS ########## */
  {
    path: '/administracao/monitor',
    name: 'processor',
    component: () => import('@/views/administracao/monitor/Monitor.vue'),
  },
  {
    path: '/administracao/monitor/:id',
    name: 'processorDetails',
    component: () => import('@/views/administracao/monitor/MonitorNovo.vue'),
  },
  /* ########## REGISTROS ########## */
  {
    path: '/registros',
    name: 'register',
    component: () => import('@/views/sistema/registros/Registros.vue'),
  },
  {
    path: '/sistema/registros/cadastro/novo',
    name: 'registerNew',
    component: () => import('@/views/sistema/registros/RegistroNovo.vue'),
  },
  {
    path: '/sistema/registros/:id/editar',
    name: 'registerUpdate',
    component: () => import('@/views/sistema/registros/RegistroNovo.vue'),
  },
  /* ########## PERFIL ########## */
  {
    path: '/meu_perfil',
    name: 'myProfile',
    component: () => import('@/views/Perfil.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

async function canUserAccess(route) {
  if (
    route.name == 'dashboard' ||
    route.name == 'login' ||
    route.name == 'recoveryPassword' ||
    route.name == 'candidate' ||
    route.name == 'candidateUid'
  ) {
    return true
  }

  const rawUser = localStorage.getItem('user')

  if (!rawUser) {
    return 'login'
  }

  const user = JSON.parse(rawUser)
  const screen = user.screen

  const group = user.group[0].idGroup

  if (group === 1) {
    return true
  }

  const path = []
  let routeName = []

  if (route.name.includes('Update')) {
    const splitResult = route.name.split('Update')
    routeName = splitResult.length > 1 ? splitResult[0] : route.name
  } else if (route.name.includes('New')) {
    const splitResult = route.name.split('New')
    routeName = splitResult.length > 1 ? splitResult[0] : route.name
  } else if (route.name.includes('Details')) {
    const splitResult = route.name.split('Details')
    routeName = splitResult.length > 1 ? splitResult[0] : route.name
  } else {
    routeName = route.name
  }

  for (let i = 0; i < screen.length; i++) {
    const screenRoute = screen[i].screenRoute.replace(/^\//, '')
    if (screenRoute == routeName && screen[i].status !== 0) {
      path.push(route.path)
    }
  }

  if (path.length > 0) {
    return true
  }
}

router.beforeEach(async (to, from) => {
  const canAccess = await canUserAccess(to)
  if (!canAccess) {
    alert('Você não tem autorização para acessar essa tela !')
    return 'dashboard'
  }
})

export default router
