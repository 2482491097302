<template>
  <div class="col-12">
    <span class="text-danger"><b>* </b><small>Campo obrigatório.</small></span>
  </div>
</template>

<script>
export default {
  name: "TheRequiredLabel"
}
</script>

<style></style>