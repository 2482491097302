<template>
  <div class="d-flex flex-column justify-content-between">
    <div class="mx-3">
      <ul class="nav nav-pills flex-column">
        <li v-for="(menuItem, i) in menusAuthorized" :key="i">
          <hr v-if="menuItem.isSeparator" class="menu-separator" />
          <a
            v-else-if="menuItem.code === 'linkButton'"
            :href="menuItem.url"
            target="_blank"
            class="item-firstLevel nav-link text-white hstack gap-1">
            <i :class="menuItem.icon"></i>
            <span class="sidebar-item-title text-sm">{{ menuItem.title }}</span>
          </a>
          <a
            v-else
            href="#"
            :class="menuItemClasses(menuItem, menuItem.code)"
            data-bs-toggle="collapse"
            :aria-expanded="menuItem.status"
            @click.prevent="navItemCollapse(i), goTo(menuItem.code)">
            <div class="col-12 d-flex justify-content-start align-items-center">
              <i :class="menuItemIcon(menuItem)"></i>
              <span class="sidebar-item-title text-sm ms-2">{{ menuItem.title }}</span>
              <span class="ms-4"></span>
            </div>
          </a>
          <div v-if="menuItem.children.length > 0" :class="divSubItemClasses(menuItem)">
            <ul class="btn-toggle-nav list-unstyled fw-normal p-1 ms-3">
              <li v-for="(subItem, j) in menuItem.children" :key="j">
                <a href="#" :class="menuSubItemClasses(subItem.code, subItem.child)" @click.prevent="goTo(subItem.code)">
                  <i :class="menuSubItemIcon(subItem.code, subItem.child, subItem.icon)"></i>
                  <span class="text-sm">{{ subItem.title }}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <div class="card card-body mb-2 mx-2 s-footer" :class="filterToggle ? 's-card-hover' : 's-bg-dark'">
      <div class="col-12">
        <s-transition>
          <div class="mx-1" v-if="filterToggle">
            <div class="row">
              <div class="col-12">
                <a href="#" class="form-control form-control-sm text-warning text-sm" @click="logout">
                  <span class="me-2">Sair</span>
                  <i class="bi bi-box-arrow-right"></i>
                </a>
              </div>
              <div class="col-12">
                <a
                  href="#"
                  class="form-control form-control-sm text-warning text-sm"
                  :class="route == 'myProfile' ? 'info-menu-active' : ''"
                  @click="$router.push({ name: 'myProfile' })">
                  <span> Dados Cadastrais </span>
                </a>
              </div>
            </div>
          </div>
        </s-transition>
      </div>
      <hr class="text-white" />
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="avatar d-flex flex-column justify-content-center align-items-center rounded-circle bg-light col-md-3">
          <i class="bi bi-person-fill"></i>
        </div>
        <div class="row ms-1">
          <div class="col-12 text-sm text-white">
            <b>{{ user.name }}</b>
          </div>
          <div class="col-12 text-sm" style="color: var(--primary-lighten)">
            {{ user.email.length > 15 ? `${user.email.substring(0, 15)}...` : user.email }}
          </div>
        </div>
        <div class="ms-3">
          <i class="bi bi-list text-warning" @click="filterToggle = !filterToggle" style="cursor: pointer"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { baseApiUrl } from '@/global'
import axios from 'axios'

export default {
  data() {
    return {
      subMenu: null,
      filterToggle: false,
      menusAuthorized: null,
    }
  },

  computed: {
    ...mapState(['logged', 'user']),
    route() {
      return this.$route.name
    },
  },

  props: {
    menuList: Array,
  },

  methods: {
    navItemCollapse(index) {
      this.menuItems = this.menusAuthorized.map((item, i) => {
        item.status = !item.status
        if (i !== index) item.status = false
        return item
      })
    },

    checkPermission() {
      const screen = this.user.screen

      if (this.user.group[0].idGroup === 1) {
        this.menusAuthorized = this.menuList
      } else {
        const authorizedMenus = []

        for (let i = 0; i < screen.length; i++) {
          const screenRoute = screen[i].screenRoute.replace(/^\//, '')
          for (let j = 0; j < this.menuList.length; j++) {
            if (this.menuList[j].code === screenRoute && screen[i].status !== 0) {
              authorizedMenus.push(this.menuList[j])
            } else if (
              this.menuList[j].children.length > 0 &&
              this.menuList[j].children.some((child) => child.code === screenRoute && screen[i].status !== 0)
            ) {
              const adminMenu = this.menuList[j]

              adminMenu.children = adminMenu.children.filter((child) =>
                screen.some((route) => route.screenRoute.replace(/^\//, '') === child.code && route.status !== 0)
              )

              if (adminMenu.children.length > 0 && !authorizedMenus.some((menu) => menu.code === adminMenu.code)) {
                authorizedMenus.push(adminMenu)
              }
            }
          }
        }

        this.menusAuthorized = authorizedMenus
      }
    },

    divSubItemClasses(menuItem) {
      let classes = 'collapse'

      if (menuItem.status) classes += 'show'
      return classes
    },

    menuItemClasses(menuItem, code) {
      let classes = 'item-firstLevel nav-link text-white hstack gap-1 '

      if (code === this.route) {
        this.subMenu = null
        classes += 'active'
      } else if (this.route) {
        if (this.route.includes('New') && this.route.split('New')[0] == code) {
          this.subMenu = null
          classes += 'active'
        }

        if (this.route.includes('Update') && this.route.split('Update')[0] == code) {
          this.subMenu = null
          classes += 'active'
        }
      } else {
        classes += ''
      }

      if (menuItem.children.length > 0) {
        menuItem.children.find((e) => e.code == this.subMenu) ? (classes += 'btn-toggle active') : (classes += '')
      }

      return classes
    },

    menuSubItemClasses(code, child) {
      let classes = 'nav-link text-white hstack gap-3 '

      if (code === this.route) {
        this.subMenu = code
        classes += 'subItemActive'
      } else if (this.route) {
        if (this.route.includes('New') && (this.route.split('New')[0] == code || this.route.split('New')[0] == child)) {
          this.subMenu = code
          classes += 'subItemActive'
        }

        if (this.route.includes('Update') && (this.route.split('Update')[0] == code || this.route.split('Update')[0] == child)) {
          this.subMenu = code
          classes += 'subItemActive'
        }

        if (
          this.route.includes('Children') &&
          (this.route.split('Children')[0] == child || this.route.split('Children')[0] == code)
        ) {
          this.subMenu = code
          classes += 'subItemActive'
        }
      } else {
        classes += ''
      }

      return classes
    },

    menuSubItemIcon(code, child, icon) {
      if (code === this.route) {
        this.subMenu = code
        icon = 'bi bi-circle-fill'
      } else if (this.route) {
        if (this.route.includes('New') && (this.route.split('New')[0] == code || this.route.split('New')[0] == child)) {
          this.subMenu = code
          icon = 'bi bi-circle-fill'
        }

        if (this.route.includes('Update') && (this.route.split('Update')[0] == code || this.route.split('Update')[0] == child)) {
          this.subMenu = code
          icon = 'bi bi-circle-fill'
        }

        if (
          this.route.includes('Children') &&
          (this.route.split('Children')[0] == child || this.route.split('Children')[0] == code)
        ) {
          this.subMenu = code
          icon = 'bi bi-circle-fill'
        }
      } else {
        icon += ''
      }

      return icon
    },

    menuItemIcon(menuItem) {
      let classes = `${menuItem.icon} me-2`
      return classes
    },

    goTo(code) {
      this.$router.push({ name: code })
    },

    logout() {
      window.location.reload()
      localStorage.removeItem('user')
      this.$store.dispatch('setLogged', false)
      this.$store.dispatch('setUser', null)
      this.$router.push({ name: 'login' })
    },
  },

  async mounted() {
    this.menuSubItemClasses(this.menu)
    this.menuSubItemClasses(this.menu)
    this.checkPermission()
  },
}
</script>

<style>
.subItemActive {
  background-color: #acacac !important;
}

.item-firstLevel:hover {
  color: #000;
  background-color: #301c46;
}

.item-firstLevel.nav-link.active {
  background-color: var(--primary);
}

.s-line {
  border-top: 2px solid #fff;
}

.s-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

hr {
  border-top: solid;
}

.avatar {
  width: 35px;
  height: 35px;
}

.avatar i {
  font-size: 25px;
  color: var(--primary-hover);
}

a.form-control:hover {
  text-decoration: none;
  color: inherit;
  background-color: var(--warning-hover);
}

a.form-control:focus {
  text-decoration: none;
  color: inherit;
  background-color: var(--warning-hover);
}

.info-menu-active {
  text-decoration: none;
  background-color: var(--warning-hover) !important;
}

a.form-control {
  background-color: #1a1a1a;
}

.s-card-hover {
  background-color: #1a1a1a;
}

.menu-separator {
  border-top: 1px solid #fff;
  margin: 10px 0;
}
</style>
