<template>
  <div class="card-status text-white text-sm mb-2" :style="`background-color: ${object.background};`" v-if="object">
    <div class="d-flex align-items-center px-2 py-1">
      <i class="me-1" :class="object.icon"></i>
      <label class="text-sm">{{ object.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
  },

  data: () => ({
    object: null,
  }),

  methods: {
    filterStatus(status) {
      switch (status) {
        case 'started':
          this.object = {
            label: 'Ativo',
            background: '#92979c',
            icon: 'bi bi-list',
          }
          break
        case 'active':
          this.object = {
            label: 'Ativo',
            // background: '#e2e8f0',
            background: '#92979c',
            icon: 'bi bi-list',
          }
          break
        case 'uploading':
          this.object = {
            label: 'Hospedando',
            background: '#bef264',
            icon: 'bi bi-list',
          }
          break
        case 'errorNotSentLink':
          this.object = {
            label: 'Ativo',
            background: 'bg-danger',
            icon: 'bi bi-list',
          }
          break
        case 'errorNotSentResult':
          this.object = {
            label: 'Resultado',
            background: 'bg-light',
            icon: 'bi bi-x-lg',
          }
          break
        case 'pending':
          this.object = {
            label: 'Pendente',
            background: 'bg-light',
            icon: 'bi bi-clock-fill',
          }
          break
        case 'analyzing':
          this.object = {
            label: 'Analisando',
            background: '#ffcc00',
            icon: 'bi bi-clock-fill',
          }
          break
        case 'generating':
          this.object = {
            label: 'Gerando',
            background: '#22c55e',
            icon: 'bi bi-clock-fill',
          }
          break
        case 'finished':
          this.object = {
            label: 'Concluído',
            background: '#4d7c0f',
            icon: 'bi bi-check-circle-fill',
          }
          break
        case 'expired':
          this.object = {
            label: 'Expirado',
            background: '#EC9F00',
            icon: 'bi bi-exclamation-circle-fill',
          }
          break
        case 'disable':
          this.object = {
            label: 'Desativado',
            background: '#84828F',
            icon: 'bi bi-link-45deg',
          }
          break
        default:
          this.object = {
            label: 'Ativo',
            background: 'bg-danger',
            icon: 'bi bi-list',
          }
          break
          break
      }
    },
  },

  mounted() {
    this.filterStatus(this.status)
  },

  watch: {
    status() {
      this.filterStatus(this.status)
    },
  },
}
</script>

<style>
.card-status {
  min-width: 100px;
  border-radius: 4px;
  display: inline-block;
}
</style>
