<template>
  <div class="modal fade" ref="modalPattern" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-white" style="background-color: #602a84;">
          <span class="fs-5">Atenção !</span>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body text-dark">
          <span>{{ text }}</span>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <s-button
            type="submit"
            btnClass="text-sm text-white me-2"
            label="Confirmar"
            color="primary"
            data-bs-dismiss="modal"
            @click="emitConfirm"
          />
          <s-button
            type="button"
            btnClass="text-sm text-secondary cancel-button"
            label="Cancelar"
            color="white"
            data-bs-dismiss="modal"
            @click="emitCancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "modalPattern",

  props: {
    text: String
  },

  methods: {
    emitConfirm() {
      this.$emit('confirm')
    },

    emitCancel() {
      this.$emit('cancel')
    }
  },
}
</script>

<style></style>