<template>
  <div :class="`mb-3 ${divClass}`">
    <s-label :label="label" :required="required" />
    <div class="input-group">
      <input
        :ref="ref"
        v-model="inputValue"
        :type="typePasswordInput"
        :placeholder="placeholder"
        class="form-control form-control-sm text-sm"
        :class="{ 'is-invalid': hasError }"
        @blur="updateValue"
        @keydown.enter.prevent="emitEnter" />
      <span class="input-group-text text-sm iconButton" @click="toggleVisibility">
        <i :class="iconClass"></i>
      </span>
      <div class="invalid-feedback" v-if="hasError">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { validateRequired } from '@/rule/validate'

export default defineComponent({
  props: {
    ref: String,
    divClass: String,
    label: String,
    placeholder: String,
    required: Boolean,
    modelValue: String,
  },

  inheritAttrs: false,

  data: () => ({
    view: false,
    inputValue: '',
    error: null,
    hasError: false,
    typePasswordInput: 'password',
  }),

  methods: {
    update() {
      this.$emit('update:modelValue', this.inputValue)
    },

    updateValue() {
      this.hasError = false
      this.error = null

      validateRequired(this.inputValue, this.required, this, `${this.label} é obrigatório.`)

      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/
      if (!regex.test(this.inputValue)) {
        this.hasError = true
        let errorMessage = 'A senha deve conter:'
        if (!/.{8,}/.test(this.inputValue)) errorMessage += ' ao menos 8 caracteres;'
        if (!/(?=.*\d)/.test(this.inputValue)) errorMessage += ' 1 número;'
        if (!/(?=.*[a-z])/.test(this.inputValue)) errorMessage += ' 1 letra minúscula;'
        if (!/(?=.*[A-Z])/.test(this.inputValue)) errorMessage += ' 1 letra maiúscula;'
        if (!/(?=.*[!@#$%^&*])/.test(this.inputValue)) errorMessage += ' 1 caractere especial (!@#$%^&*);'

        this.error = errorMessage
      }

      if (!this.hasError) {
        this.update()
      }
    },

    toggleVisibility() {
      this.view = !this.view
      this.view ? (this.typePasswordInput = 'text') : (this.typePasswordInput = 'password')
    },

    emitEnter() {
      this.$emit('enter')
    },
  },

  emits: ['update:modelValue'],

  created() {
    this.inputValue = this.modelValue
  },

  computed: {
    iconClass() {
      return this.view ? 'bi bi-eye' : 'bi bi-eye-slash'
    },
  },

  watch: {
    inputValue() {
      this.update()
    },

    modelValue(value) {
      if (value !== this.inputValue) {
        this.inputValue = value
      }
    },
  },
})
</script>

<style>
.input-group-text {
  background-color: #f3eeff;
  border: none;
}

.iconButton {
  cursor: pointer;
}
</style>
