<template>
  <div class="col-md-5 d-flex flex-column justify-content-center align-items-center">
    <div class="row text-center">
      <div class="col-12 mb-4">
        <img src="@/assets/lwp.png" class="logoContent" />
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-12 text-sm">Sistema de gerenciamento de registros</div>
        <div class="col-md-12 text-lg mt-3">
          <b v-if="!recoveryPassword">Login</b>
          <b v-else>Recuperar Senha</b>
          <hr class="line" />
        </div>
        <div class="col-md-12">
          <form ref="form" @submit.prevent="submitForm">
            <div v-if="!recoveryPassword">
              <s-input-text ref="email" v-model="object.email" divClass="col-12" label="Usuário" placeholder="Digite seu login" />
              <s-input-password ref="password" v-model="object.password" divClass="col-12" label="Senha" placeholder="Digite sua senha" @enter="login" />
              <s-input-check ref="keepConnect" v-model="object.keepConnect" divClass="col-12 text-sm" label="Me manter conectado" />
              <div class="d-grid gap-2 col-5 mx-auto mb-2">
                <s-button class="rounded-pill" label="Entrar" color="primary" colorFont="light" type="submit" @click="login()" />
              </div>
              <div class="col-12 text-center">
                <s-button class="btn-sm text-xs" label="Esqueceu sua senha?" color="bg-light" colorFont="primary" type="submit" @click="recoveryPassword = true" />
              </div>
            </div>
            <div v-else>
              <p class="text-xs mt-2">Insira seu e-mail para receber o link de alteração de senha.</p>
              <s-input-text ref="emailRecovery" v-model="object.emailRecovery" divClass="col-12" label="E-mail" placeholder="Digite seu e-mail" />
              <div class="d-grid gap-2 col-5 mx-auto mb-2">
                <s-button class="rounded-pill" label="Enviar" color="primary" colorFont="light" type="submit" :disabled="!object.emailRecovery" @click="recPassword()" />
              </div>
              <div class="col-12 text-center">
                <s-button class="text-xs danger" label="Cancelar" icon="x-circle" color="bg-light" colorFont="secondary" type="submit" @click="recoveryPassword = false" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <s-modal-error ref="modalMessage" modalTitle="Falha ao conectar!" :modalBody="modalBody" />
  </div>
</template>

<script>
import axios from 'axios'
import { get, setHeaders } from '@/crud.js'
import { baseApiUrl } from '@/global'

export default {
  name: 'Login',

  data: () => ({
    object: {},

    recoveryPassword: false,
    Modal: null,
    modalBody: null,
  }),

  methods: {
    async login() {
      if (!this.object.password || !this.object.email) {
        this.modalBody = 'Informe o Usuário e a Senha de acesso.'
        this.modalMessage.show()
      } else {
        const url = `${baseApiUrl}/login`

        console.log('aqui')

        await axios
          .post(url, this.object)
          .then(async (res) => {
            setHeaders(res.data.token)
            this.$store.dispatch('setLogged', true)
            this.$store.dispatch('setUser', res.data)
            this.$router.push({ name: 'dashboard' })

            localStorage.setItem('user', JSON.stringify(res.data))
            return true
          })
          .catch((err) => {
            console.log(err)
            if (err.response.status == 401) {
              this.modalBody = 'Usuário ou senha incorretos. Por favor, verifique.'
              this.modalMessage.show()
            } else if (err.response.status == 400) {
              this.modalBody = 'Usuário ou senha não informados. Por favor, verifique.'
              this.modalMessage.show()
            } else if (err.response.status == 403) {
              this.modalBody = 'Usuário inativo.'
              this.modalMessage.show()
            }
          })
      }
    },

    async recPassword() {
      const url = `${baseApiUrl}/recoveryPassword`
      axios.defaults.withCredentials = true

      await axios
        .post(url, { email: this.object.emailRecovery })
        .then(() => {
          this.$store.dispatch('setShowToast', true)
          this.$store.dispatch('setToastMessage', 'Email de recuperação enviado com sucesso !')
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setTimeout(() => {
            this.$router.go('/login')
          }, 2500)
        })
    },

    interpretador(param) {
      eval(`this.${param}()`)
    },
  },

  mounted() {
    this.modalMessage = new this.$Modal(this.$refs.modalMessage.$refs.modalPattern)
  },
}
</script>

<style>
.logoContent {
  width: 50%;
  height: auto;
}

.line {
  margin: 10px;
  border: 1px solid;
  /* color: #e8e1f8; */
  color: var(--primary-hover);
  width: 100%;
}

.iconButton {
  cursor: pointer;
}
</style>
