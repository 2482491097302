<template>
  <div
    class="modal fade"
    ref="modalPattern"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header s-bg-primary">
          <span class="fs-5 text-white">{{ modalTitle }}</span>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body text-dark">
          <span>{{ modalBody }}</span>
        </div>
        <div class="modal-footer">
          <s-button
            label="Entendi"
            color="primary"
            colorFont="light"
            class="btn-sm"
            data-bs-dismiss="modal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalPattern",

  props: {
    modalTitle: String,
    modalBody: String,
  },
};
</script>

<style>
@import "@/style/theme.css";
</style>