<template>
    <div style="max-width: 100%;" class="chartComponent">
        <div ref="chart" style="height: 400px; min-width: 0;"></div>
    </div>
</template>
  
<script>
  import * as echarts from 'echarts'
  
  export default {
    name: 'TheDoubleBarChart',
  
    props: {
        data: Array,
        series1: Array,
        series2: Array,
        name1: String,
        name2: String,
    },
  
    mounted() {
        this.chart = echarts.init(this.$refs.chart)
    
        const container = document.querySelector('.chartComponent')

        new ResizeObserver(() => this.chart.resize()).observe(container)
    
        this.updateChart()
    },
  
    methods: {
        updateChart() {
            const option = {
                tooltip: {
                    trigger: 'axis',
                },
                calculable: true,
                legend: {
                    data: [this.name1, this.name2]
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.data
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: this.name1,
                        type: 'bar',
                        data: this.series1,
                    },
                    {
                        name: this.name2,
                        type: 'bar',
                        data: this.series2,
                        itemStyle: {
                            color: '#602A84',
                        },
                    }
                ]
            }

            this.chart.setOption(option)
        },
    },
  
    watch: {
        data() {
            this.updateChart()
        }
    },
  
    beforeUnmount() {
        this.chart.dispose()
    },
  }
</script>
  