<template>
  <div style="max-width: 100%;" class="chartComponent">
    <div ref="chart" style="height: 400px; min-width: 0;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'TheBarChart',

  props: {
    data: Array,
    series: Array,
  },

  mounted() {
    this.chart = echarts.init(this.$refs.chart)

    const container = document.querySelector('.chartComponent');

    new ResizeObserver(() => this.chart.resize()).observe(container);

    this.updateChart();
  },

  methods: {
    updateChart() {

      const option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.data
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.series,
            type: 'bar',
          },
        ],
      };       

      this.chart.setOption(option);
    },
  },

  watch: {
    data() {
      this.updateChart();
    }
  },

  beforeUnmount() {
    this.chart.dispose()
  },
}
</script>
